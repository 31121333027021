<template>
  <li>
    <button :class="[filterState ]" class="button button--filter" @click="onFilterClick(tag.TagId)">
      {{ tag.TagName }}
    </button>
  </li>
</template>

<script>


export default {
  name: "FilterTagsList",
  data() {
    return {
      isActive: false,
    }
  },
  props: {
    tag: {}
  },
  methods: {
    onFilterClick: function (categoryId) {
      this.isActive = !this.isActive;
      this.$emit("onFilterClick", categoryId);
    }
  },
  computed: {
    filterState() {
      return this.isActive ? 'button--selected' : '';
    }
  }
};
</script>
