<template>
  <li class="calendar-event-item calendar-event-page-partial content-area-item content-area-item--third site-page-partial"
       :class="calendar.IsRecurring ? 'site-page-partial--dark-green-blue' : ''"
       @click="goToPage(calendar.EventUrl)">
    <article class="site-page-partial__content site-page-partial__content--has-date-field">
      <figure v-if="calendar.ImageHtmlTag" class="calendar-event__image-container site-page-partial__image-container" v-html="calendar.ImageHtmlTag"></figure>
      <div v-if="!calendar.ImageHtmlTag" class="site-page-partial__no-image-container">
        <span class="site-page-partial__train-icon icon icon--train"></span>
      </div>
      <div class="site-page-partial__text-container">
        <div class="site-page-partial__date-field">
          <span class="site-page-partial__date-field-icon icon icon--clock"></span>
          <span class="calendar-event-item__date-string site-page-partial__date-field-text" v-html="calendarDate"></span>
        </div>
        <ul v-if="!!calendar.CompleteTags?.length" class="site-page-partial__category-list tag-container">
          <li class="site-page-partial__category-item tag tag--small" v-for="(tag) in calendar.CompleteTags">
            {{ tag.TagName }}
          </li>
        </ul>
        <h3 class="site-page-partial__heading heading heading--small">
          <a v-bind:href="calendar.EventUrl" class="site-page-partial__link">
            {{ calendar.Heading }}
          </a>
        </h3>
        <p class="site-page-partial__excerpt" v-html="calendar.MainIntro"></p>
        <div class="site-page-partial__arrow-icon-container">
          <span class="site-page-partial__arrow-icon icon icon--arrow-right"></span>
        </div>
      </div>
    </article>
  </li>
</template>

<script>
export default {
  name: "Calendar",
  props: {
    calendar: {},
    translations: {},
    culture: ""
  },
  methods: {
    goToPage: (link) => {
      window.location.href = link;
    }
  },
  computed: {
    calendarDate: function () {
      const startDate = new Date(this.calendar.Start);

      // Special case for events that have a display string
      if (!!this.calendar.DisplayString) {
        return this.calendar.DisplayString;
      }

      //Recurring event
      if (this.calendar.IsRecurring) {
        const endDate = new Date(this.calendar.PeriodEndDate);
        const startDayAndMonth = startDate.toLocaleDateString(this.culture, {
          day: 'numeric',
          month: 'short'
        }).replaceAll('.', '');
        const startTime = startDate.toLocaleTimeString(this.culture, {hour: '2-digit', minute: '2-digit'});
        const endDayAndMonth = endDate.toLocaleDateString(this.culture, {
          day: 'numeric',
          month: 'short'
        }).replaceAll('.', '');
        let timeString = this.translations.SeveralTimes;

        //Recurring within the month
        if (startDate.getMonth() === endDate.getMonth()) {
          return startDate.getDate() + " - " + endDayAndMonth + ", " + (this.calendar.NumberOfSchedulings > 1 ? timeString : startTime);
        }

        //Recurring stretching between months
        return startDayAndMonth + " - " + endDayAndMonth + ", " + (this.calendar.NumberOfSchedulings > 1 ? timeString : startTime);
      }

      //Single event
      return startDate.toLocaleDateString(this.culture, {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>
