<template>
  <div class="calendar__wrapper">
    <div class="splide">
      <div class="splide__track">
        <ul class="splide__list">
          <li v-for="(month, key, index) in filteredCalendarEvents" class="splide__slide">
            <div class="header-section">
              <button :class="index === 0 ? 'invisible' : ''" class="header-section-month prev" @click="prevSlide"
                      tabindex="1">
                {{ index === 0 ? "" : Object.keys(filteredCalendarEvents)[index - 1] }}
              </button>
              <h2 class="header-section-current">{{ key }}</h2>
              <button class="header-section-month next" @click="nextSlide" tabindex="1">
                {{ index + 1 < numberOfMonths ? Object.keys(filteredCalendarEvents)[index + 1] : "" }}
              </button>
            </div>
            <ul class="calendar__container content-area content-area--thirds">
              <Calendar
                v-for="calendar in month"
                :calendar="calendar"
                :translations="translations"
                :culture="culture"
                @onError="onError"
              />
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "./calendar.vue";
import Splide from '@splidejs/splide';
import { monthsEnglish, monthsSwedish } from './../services/calendarService';

export default {
  name: "CalendarList",
  components: {
    Calendar: Calendar,
  },
  data() {
    return {
      splide: {},
      monthsAsStrings: this.culture === "sv" ? monthsSwedish : monthsEnglish
    };
  },
  mounted() {
    this.initSplide();
  },
  props: {
    filteredCalendarEvents: [],
    translations: {},
    culture: ""
  },
  methods: {
    onError: function (error) {
      this.$emit("onError", error);
    },
    initSplide: function () {
      const options = {
        type: 'fade',
        keyboard: 'focused',
        pagination: false
      };

      this.splide = new Splide(('.splide'), options);
      this.splide.mount();

      this.goToCurrentMonth();
    },
    nextSlide: function () {
      this.splide.go('>');
    },
    prevSlide: function () {
      this.splide.go('<');
    },
    goToCurrentMonth: function () {
      // Go to current month in the splide
      Object.keys(this.filteredCalendarEvents).forEach((month, index) => {
        let currentMonth = (new Date()).getMonth();
        
        if (month === this.monthsAsStrings[currentMonth]) {
          this.splide.go(index);
        }
      });
    }
  },
  computed: {
    numberOfMonths: function () {
      return Object.keys(this.filteredCalendarEvents).length;
    }
  },
  watch: {
    filteredCalendarEvents: {
      handler: function () {
        // Use setTimeout to make sure the DOM is updated before destroying and reinitializing the splide
        setTimeout(() => {
          this.splide.destroy(true);
          this.initSplide();
        });
      }
    }
  }
};
</script>
