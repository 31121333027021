<template>
  <div class="spinner">
    <svg width="85" height="158" viewBox="0 0 85 158" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M58.343 136.872V133.348C58.343 131.587 56.9765 130.16 55.2683 130.16H52.8768V122.108L54.9507 118.036C55.2536 117.441 54.8131 116.741 54.1358 116.741H46.1515C45.4743 116.741 45.0338 117.441 45.3367 118.036L47.4106 122.108V130.16H33.745V126.581C33.745 125.593 32.9292 124.792 31.9229 124.792H24.6346C23.6283 124.792 22.8125 125.593 22.8125 126.581V148.946H25.5456C25.5456 151.912 27.9918 154.314 31.0119 154.314C34.032 154.314 36.4781 151.912 36.4781 148.946C36.4781 151.912 38.9242 154.314 41.9443 154.314C44.9644 154.314 47.4106 151.912 47.4106 148.946C47.4106 151.912 49.8567 154.314 52.8768 154.314C55.8969 154.314 58.343 151.912 58.343 148.946H64.8401C65.5677 148.946 66.0018 148.15 65.5981 147.555L58.343 136.869V136.872ZM31.0119 151.632C29.5032 151.632 28.2787 150.43 28.2787 148.949C28.2787 147.467 29.5032 146.265 31.0119 146.265C32.5205 146.265 33.745 147.467 33.745 148.949C33.745 150.43 32.5205 151.632 31.0119 151.632ZM41.9443 151.632C40.4356 151.632 39.2112 150.43 39.2112 148.949C39.2112 147.467 40.4356 146.265 41.9443 146.265C43.453 146.265 44.6774 147.467 44.6774 148.949C44.6774 150.43 43.453 151.632 41.9443 151.632ZM52.8768 151.632C51.3681 151.632 50.1437 150.43 50.1437 148.949C50.1437 147.467 51.3681 146.265 52.8768 146.265C54.3855 146.265 55.6099 147.467 55.6099 148.949C55.6099 150.43 54.3855 151.632 52.8768 151.632Z"
        fill="#9AACAC"/>
      <g clip-path="url(#clip0_3418_13373)">
        <path
          id="smoke-19"
          d="M50.1943 81.8837C50.1943 81.8837 49.9597 81.9946 49.2557 82.272C47.6355 82.9103 44.084 84.2738 42.2157 85.0454C39.6606 86.1006 34.061 89.5385 34.061 89.5385C34.061 89.5385 31.5308 89.2402 29.7196 89.0947C21.8744 88.4649 16.5497 82.8783 16.8732 79.2763C17.0554 77.2477 18.2352 76.3913 21.5061 75.4491C22.8699 75.0562 28.546 74.3396 28.546 74.3396L38.2848 73.3967C38.2848 73.3967 42.6749 75.0179 43.7995 75.671C45.5307 76.6762 47.2879 78.2281 48.493 79.0547C48.8792 79.3196 49.3945 79.6782 49.6663 80.1641C50.1068 80.9512 50.1943 81.8837 50.1943 81.8837Z"
          fill="#36595A"/>
        <path
          id="smoke-14"
          d="M61.5734 94.2537C61.5734 96.1531 56.7041 98.8578 56.7041 98.8578C56.7041 98.8578 56.2934 97.9813 54.1814 97.582C52.7147 97.3046 51.4827 97.3601 51.4827 97.3601C51.4827 97.3601 52.094 96.2201 52.128 95.1412C52.1498 94.453 51.952 93.699 51.776 93.3107C51.4995 92.7005 50.4854 91.7575 50.4854 91.7575L52.656 90.4262C52.656 90.4262 55.472 90.6481 56 90.7036C56.4665 90.7526 57.8093 90.8996 58.8747 91.3137C60.4306 91.9186 61.5734 93.1263 61.5734 94.2537Z"
          fill="#36595A"/>
        <path
          id="smoke-11"
          d="M51.9548 99.3017C52.166 99.8009 52.3068 100.966 52.3068 100.966C52.3068 100.966 50.8111 101.475 49.9015 101.798C49.6584 101.884 50.0188 102.186 50.0188 102.186C50.0188 102.186 48.7724 102.186 48.4935 102.186C47.0678 102.186 45.9121 101.267 45.9121 100.134C45.9121 99.0002 47.0678 98.0813 48.4935 98.0813C48.7603 98.0813 49.2573 98.1303 49.7255 98.0813C50.3049 98.0207 50.8402 97.8594 50.8402 97.8594C50.8402 97.8594 51.6028 98.4696 51.9548 99.3017Z"
          fill="#36595A"/>
        <path
          id="smoke-10"
          d="M50.7244 102.184C51.0517 102.23 51.2043 102.289 51.3695 102.352C51.4653 102.388 51.7207 102.519 51.7207 102.519C51.7207 102.519 51.5136 102.783 51.3101 102.852C51.0721 102.932 50.6061 102.907 50.6061 102.907L50.0258 102.184C50.0258 102.184 50.384 102.137 50.7244 102.184Z"
          fill="#36595A"/>
        <path
          id="smoke-5"
          d="M51.5075 103.741L51.8935 103.961C51.8935 103.961 53.7818 104.875 53.947 105.515C54.2031 106.508 50.4856 108.898 50.4856 108.898L49.9785 109.344C49.9785 109.344 49.0459 108.389 48.8032 107.623C48.5534 106.834 48.4376 106.262 48.8032 105.515C49.0286 105.054 49.2703 104.841 49.6837 104.517C50.2852 104.046 51.5075 103.741 51.5075 103.741Z"
          fill="#36595A"/>
        <path
          id="smoke-2"
          d="M51.0673 110.591C51.4193 111.035 52.4076 112.2 52.182 113.088C52.0116 113.758 51.0673 114.141 51.0673 114.141C51.0673 114.141 49.4247 113.032 49.0727 111.812C48.6753 110.434 50.068 109.372 50.068 109.372C50.068 109.372 50.2753 109.593 51.0673 110.591Z"
          fill="#36595A"/>
        <path
          id="smoke-22"
          d="M49.8136 73.679C58.4896 69.1486 66.4036 65.6298 66.4036 55.7934C66.4036 50.8693 64.5328 44.6693 60.0134 41.9318C56.9234 40.0602 53.968 39.2631 49.8136 39.2631C39.5828 39.2631 28.5336 46.1509 28.5336 54.4417C28.5336 54.4417 26.1305 62.5592 30.82 68.1747C35.5095 73.7902 44.5962 76.4034 49.8136 73.679Z"
          fill="#36595A"/>
        <path
          id="smoke-15"
          d="M50.1551 81.8637L53.4405 80.2552C53.4405 80.2552 54.8497 80.8032 55.5499 81.5321C57.375 83.4321 57.2598 84.631 56.5472 86.4135C55.8346 88.1961 53.4991 89.7729 52.6193 90.4065C50.3286 92.0558 49.2484 92.1841 47.2195 93.5137C45.4341 94.6837 42.8194 96.731 42.8194 96.731C42.8194 96.731 38.6281 96.2201 35.8964 94.8451C33.4304 93.6037 33.902 89.5753 33.902 89.5753C33.902 89.5753 35.0697 87.8049 36.3071 87.0791C37.5445 86.3533 40.2392 85.8582 40.2392 85.8582C40.2392 85.8582 43.2086 85.7612 45.0501 85.359C46.5327 85.0351 48.742 84.5828 49.4486 83.8063C48.742 84.5828 50.1551 83.0298 49.4486 83.8063C50.1551 83.0298 50.1551 81.8637 50.1551 81.8637Z"
          fill="#36595A"/>
        <path
          id="smoke-8"
          d="M55.4919 102.722C57.1933 101.557 57.3693 100.115 56.6653 98.8387L52.2652 100.947C52.2652 100.947 52.3508 101.8 51.6197 102.555C51.0034 103.192 49.9775 102.504 49.3904 103.166C48.9188 103.697 48.8169 104.355 49.0972 104.996C49.4752 105.86 50.9728 105.681 51.7959 105.162C52.5 104.719 54.3577 103.498 55.4919 102.722Z"
          fill="#36595A"/>
        <path
          id="smoke-13"
          d="M50.4963 97.4355C50.4963 97.4355 48.2412 97.8287 47.637 98.0856C45.5223 98.9848 44.7785 98.8089 44.2378 98.7043C43.4606 98.554 43.0498 97.9836 42.8451 97.7659C42.7427 97.6571 42.4686 97.257 42.4686 97.257L42.8348 96.8141C42.8348 96.8141 44.9464 97.0133 45.6023 97.0069C47.241 96.9911 50.4963 97.4355 50.4963 97.4355Z"
          fill="#688283"/>
        <path
          id="smoke-3"
          d="M50.4466 108.879L52.5 107.381C52.5 107.381 53.0144 107.745 53.2036 108.103C53.3842 108.444 52.0302 109.767 52.0302 109.767L51.1503 110.654C51.1503 110.654 50.7434 110.107 50.6222 109.822C50.4844 109.498 50.4466 108.879 50.4466 108.879Z"
          fill="#36595A"/>
        <path
          id="smoke-21"
          d="M60.6547 72.0883C60.858 73.0771 60.3011 75.0882 58.9534 76.4705C57.5894 77.8695 55.4333 79.1886 55.4333 79.1886L53.4386 80.2425L51.268 79.466C51.268 79.466 49.3585 78.9659 47.8653 78.1901C46.6506 77.5591 45.8106 76.7019 45.6946 76.1377C45.2855 74.1483 49.2739 71.9218 52.9112 70.2577C54.7885 69.5366 58.3666 68.3163 58.3666 68.3163C58.3666 68.3163 59.2684 69.0308 59.8334 69.9804C60.3898 70.9156 60.5507 71.5827 60.6547 72.0883Z"
          fill="#36595A"/>
        <path
          id="smoke-23"
          d="M79.6555 24.7254C81.5487 32.842 78.3063 43.1143 68.5819 47.3722C59.8405 51.1996 50.452 52.7155 44.6457 55.4154C30.3309 62.0719 8.95199 53.788 5.76698 40.1325C2.58196 26.4771 16.5405 11.9582 36.9443 7.70362C57.348 3.44905 76.4705 11.07 79.6555 24.7254Z"
          fill="#36595A"/>
        <path
          id="smoke-12"
          d="M54.2567 97.5674C51.7857 97.1 50.502 97.4565 50.502 97.4565C50.502 97.4565 50.7866 97.8354 51.206 98.2885C51.6166 98.7323 51.9686 99.2315 52.0482 99.7518L52.262 100.951L56.6621 98.8432C56.6621 98.8432 56.0167 97.9002 54.2567 97.5674Z"
          fill="#688283"/>
        <path
          id="smoke-20"
          d="M28.0321 57.2956C28.0321 57.2956 43.1596 62.3005 45.3978 68.3338C46.5516 71.4441 42.3471 72.3282 42.3471 72.3282L38.2404 73.3821C38.2404 73.3821 44.3793 76.2531 45.8084 77.1542C49.2352 79.3146 50.152 81.8687 50.152 81.8687L53.4373 80.2601C53.4373 80.2601 49.5891 78.8552 49.0938 78.5964C45.9729 76.9658 48.6641 72.5897 51.2666 71.1628C53.8691 69.736 58.3654 68.3338 58.3654 68.3338C58.3654 68.3338 55.6645 66.7256 51.0885 65.6717C46.5124 64.6177 39.9287 61.8442 39.5882 60.5126C39.2477 59.181 40.0004 56.9628 47.6858 54.7439C55.3712 52.5251 65.1099 48.8085 65.1099 48.8085C65.1099 48.8085 63.467 43.6497 61.1204 41.8746C52.4777 35.3368 40.1739 39.8776 34.0758 45.5356C27.9776 51.1937 28.0321 57.2956 28.0321 57.2956Z"
          fill="#688283"/>
        <path
          id="smoke-17"
          d="M47.3301 90.5381C47.3301 90.5381 43.4059 91.1534 42.3542 91.5538C38.6731 92.9553 37.3794 92.6833 36.4389 92.5217C35.087 92.2893 34.3693 91.5768 34.0761 91.1886C33.9296 90.9945 33.7838 90.4659 33.8417 90.0791C33.8716 89.8796 34.0017 89.5859 34.0017 89.5859C34.0017 89.5859 37.6751 89.8918 38.8163 89.8807C41.6675 89.8531 47.3301 90.5381 47.3301 90.5381Z"
          fill="#688283"/>
        <path
          id="smoke-16"
          d="M47.3812 90.518L52.5538 90.4117L50.3966 91.7369C50.3966 91.7369 49.5238 91.1962 48.915 90.954C48.337 90.724 47.3812 90.518 47.3812 90.518Z"
          fill="#688283"/>
        <path
          id="smoke-6"
          d="M51.5528 105.706C51.8134 105.762 52.0163 105.897 52.1238 106.002C52.1833 106.059 52.2414 106.176 52.1835 106.397C52.1253 106.62 51.8274 106.839 51.8274 106.839L51.4143 106.266L51.0011 105.664C51.0011 105.664 51.2818 105.648 51.5528 105.706Z"
          fill="#688283"/>
        <path
          id="smoke-9"
          d="M51.2576 102.785C51.5233 102.713 51.6591 102.495 51.6591 102.495C51.6591 102.495 51.9862 102.664 52.0236 102.873C52.0902 103.246 51.413 103.759 51.413 103.759L50.5592 102.893C50.5592 102.893 50.9266 102.873 51.2576 102.785Z"
          fill="#688283"/>
        <path
          id="smoke-7"
          d="M49.5362 104.53C49.3289 104.723 49.0942 105 49.0942 105C49.0942 105 49.5242 105.417 49.7395 105.5C50.2336 105.688 51.0264 105.667 51.0264 105.667L51.0217 105.373L52.8463 104.502L51.4081 103.679C51.4081 103.679 49.7852 104.298 49.5362 104.53Z"
          fill="#688283"/>
        <path
          id="smoke-4"
          d="M51.845 106.835L52.4952 107.386L50.4419 108.884C50.4419 108.884 50.4066 107.983 50.7447 107.532C51.0428 107.135 51.845 106.835 51.845 106.835Z"
          fill="#688283"/>
        <path id="smoke-1"
              d="M51.6747 111.546C51.8737 111.842 52.2563 112.717 52.0855 113.266C51.9565 113.68 50.9769 114.153 50.9769 114.153C50.9769 114.153 50.4481 113.017 50.2779 112.232C50.0857 111.346 50.9708 110.603 50.9708 110.603C50.9708 110.603 51.2271 110.88 51.6747 111.546Z"
              fill="#688283"/>
        <path
          id="smoke-18"
          d="M40.4093 85.8632C40.4093 85.8632 37.7197 85.6909 36.302 86.4183C34.7425 87.2184 34.0145 89.5243 34.0145 89.5243L40.4093 85.8632Z"
          fill="#688283"/>
      </g>
      <defs>
        <clipPath id="clip0_3418_13373">
          <path d="M0 0H85V72.5C85 95.9721 65.9721 115 42.5 115C19.0279 115 0 95.9721 0 72.5V0Z" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Spinner",
};
</script>